import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'trust',
  pure: true,
})
export class TrustPipe implements PipeTransform {
  private static readonly sanitizerFnMap = {
    html: 'bypassSecurityTrustHtml' as const,
    style: 'bypassSecurityTrustStyle' as const,
    script: 'bypassSecurityTrustScript' as const,
    url: 'bypassSecurityTrustUrl' as const,
    resourceUrl: 'bypassSecurityTrustResourceUrl' as const,
  };

  constructor(private sanitizer: DomSanitizer) {}

  public transform(
    value: string | null | undefined,
    type: keyof typeof TrustPipe.sanitizerFnMap,
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl | undefined {
    if (!value) return undefined;

    const fn = this.sanitizer[TrustPipe.sanitizerFnMap[type]];
    if (!fn) throw new Error(`Invalid safe type specified: ${type}`);

    return fn(value);
  }
}
